import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { trigger } from '@angular/animations'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import moment from 'moment'
import { finalize, first, takeUntil } from 'rxjs/operators'

import { EconomyTabService } from '../../../../../core/api'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { PaymentTypes } from '../../../../../core/enums/economy/payment-types'
import { UserResponse } from '../../../../../core/interfaces/user/user-response'
import { UtilsService, ValidationService } from '../../../../../core/services'
import { BaseComponent } from '../../../../../shared/components/base/base.component'
import { SnackbarService } from '../../../../../shared/components/snackbar/snackbar.service'
import { DashboardState } from '../../../states/dashboard.state'
import { PriceCategoryResponse } from './interfaces/price-category-response'
import { PriceCategoryType } from '../enums/price-category-type'
import { InitialValues } from '../../../../../shared/modules/price/interfaces/initial-values'
import { Constants } from '../../../../../constants/constants'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { EconomyUserType } from '../enums/economy-user-type'
import { Organization } from '../../../../../core/interfaces/organization/organization'
import { SharedModule } from '../../../../../shared/modules/shared.module'
import { PriceModule } from '../../../../../shared/modules/price/price.module'
import { UserPaymentResponse } from './interfaces/user-payment-response'
import { DisableValueChangeOnWheelDirective } from '../../../../../shared/directives/disable-value-change-on-wheel.directive'

@Component({
  selector: 'app-edit-user-payment',
  templateUrl: './edit-user-payment.component.html',
  styleUrls: ['./edit-user-payment.component.scss'],
  host: { '[@editUserPaymentAnimation]': '' },
  standalone: true,
  imports: [SharedModule, PriceModule, DisableValueChangeOnWheelDirective],
  animations: [trigger('editUserPaymentAnimation', OverlayAnimations.detailsPopup)]
})
export class EditUserPaymentComponent extends BaseComponent implements OnInit {
  @Input() id: number

  // TODO: remove after switching to API-call approach
  @Input() user: Partial<UserResponse>
  @Input() userPaymentId: number
  @Input() paymentName: string
  @Input() dueDate: string
  @Input() amount: number
  @Input() includeTransactionFee: boolean
  @Input() note: string
  @Input() paidStatus: PaidStatus
  @Input() paymentType: PaymentTypes
  @Input() initialValues: InitialValues
  @Input() isOptional: boolean

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  @SelectSnapshot(DashboardState.organization) organization: Organization

  loading: boolean = false
  userPayment: UserPaymentResponse | undefined
  status: PaidStatus
  isLoadingPriceCategories: boolean = false
  priceCategories: PriceCategoryResponse[]
  customPriceCategory: PriceCategoryResponse | undefined
  customPrice: number
  isSettingCustomPrice: boolean = false
  minAge: number = Constants.minAge
  maxAge: number = Constants.maxAge
  userPaymentForm: FormGroup
  today = moment().format(DateFormat.YYYYMMDD)

  get PaidStatus() {
    return PaidStatus
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get EconomyUserType() {
    return EconomyUserType
  }

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private economyTabService: EconomyTabService,
    private snackbarService: SnackbarService,
    private utils: UtilsService
  ) {
    super()
  }

  ngOnInit() {
    this.getUserPayment()
    this.initForm()

    if (this.paymentType === PaymentTypes.MembershipFee) {
      this.isLoadingPriceCategories = true
      this.economyTabService
        .getPriceCategories(this.userPaymentId)
        .pipe(finalize(() => (this.isLoadingPriceCategories = false)))
        .subscribe((categories) => {
          this.priceCategories = categories
            .filter((category) => category.type === PriceCategoryType.Default)
            .map((category) => ({
              ...category,
              net_amount: this.utils.getNOK(category.net_amount),
              total_fee: this.utils.getNOK(category.total_fee),
              gross_amount: this.utils.getNOK(category.gross_amount)
            }))

          this.customPriceCategory = categories.find((category) => category.type === PriceCategoryType.Custom)
          if (this.customPriceCategory) {
            this.toggleCustomPrice(true)
          }
        })
    }
  }

  private getUserPayment(): void {
    this.loading = true
    this.economyTabService
      .getUserPayment(this.userPaymentId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => (this.userPayment = response))
  }

  private initForm(): void {
    this.status = this.paidStatus === PaidStatus.Optional ? PaidStatus.NotPaid : this.paidStatus

    const userPaymentForm: any = {
      status: [{ value: this.status, disabled: this.paidStatus === PaidStatus.Declined }, Validators.required],
      note: [this.note]
    }

    if (this.paymentType !== PaymentTypes.EventPayment) {
      userPaymentForm.dueDate = [
        {
          value: moment(new Date(this.dueDate)).format(DateFormat.YYYYMMDD),
          disabled: this.paidStatus === PaidStatus.Declined
        },
        Validators.required
      ]
    }

    if (this.paymentType === PaymentTypes.RegularPayment || this.paymentType === PaymentTypes.TrainingFee) {
      userPaymentForm.amount = [
        { value: this.utils.getNOK(this.amount), disabled: this.paidStatus === PaidStatus.Declined },
        Validators.required
      ]
      userPaymentForm.includeTransactionFee = [
        { value: this.includeTransactionFee, disabled: this.paidStatus === PaidStatus.Declined }
      ]
    }

    this.userPaymentForm = this.formBuilder.group(userPaymentForm)

    if (this.paymentType !== PaymentTypes.MembershipFee && this.includeTransactionFee === null) {
      this.userPaymentForm.controls.amount.valueChanges
        .pipe(first(), takeUntil(this.userPaymentForm.controls.includeTransactionFee.valueChanges))
        .subscribe(() => this.userPaymentForm.patchValue({ includeTransactionFee: false }))
    }

    this.userPaymentForm.controls.status.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value: PaidStatus) => {
        if (value === PaidStatus.PaidManually) {
          this.userPaymentForm.addControl(
            'chargeDate',
            new FormControl(this.today, [Validators.required, ValidationService.maxDate({ maxDate: this.today })])
          )
        } else {
          this.userPaymentForm.removeControl('chargeDate')
        }

        if (this.paymentType === PaymentTypes.MembershipFee && this.isSettingCustomPrice) {
          this.userPaymentForm.controls.customPrice[value === PaidStatus.NotPaid ? 'enable' : 'disable']()
          this.userPaymentForm.controls.includeTransactionFee[value === PaidStatus.NotPaid ? 'enable' : 'disable']()
        }
      })
  }

  toggleCustomPrice(isSettingCustomPrice: boolean): void {
    if (this.userPaymentForm.value.status === PaidStatus.NotPaid) {
      if (isSettingCustomPrice) {
        this.userPaymentForm.addControl(
          'customPrice',
          new FormControl(
            this.customPriceCategory ? this.utils.getNOK(this.customPriceCategory.amount) : null,
            Validators.required
          )
        )
        this.userPaymentForm.addControl(
          'includeTransactionFee',
          new FormControl(this.customPriceCategory ? this.customPriceCategory.include_fee : true)
        )

        if (this.customPriceCategory?.include_fee === null) {
          this.userPaymentForm.controls.customPrice.valueChanges
            .pipe(first(), takeUntil(this.userPaymentForm.controls.includeTransactionFee.valueChanges))
            .subscribe(() => this.userPaymentForm.patchValue({ includeTransactionFee: false }))
        }
      } else {
        this.userPaymentForm.removeControl('customPrice')
        this.userPaymentForm.removeControl('includeTransactionFee')
      }

      this.isSettingCustomPrice = isSettingCustomPrice
    }
  }

  saveUserPayment(): void {
    if (this.userPaymentForm.valid) {
      this.loading = true

      const payload: any = {
        note: this.userPaymentForm.value.note
      }

      if (this.userPaymentForm.value.status) {
        payload.payment_status = this.userPaymentForm.value.status
      }

      if (this.paymentType !== PaymentTypes.EventPayment && this.userPaymentForm.value.dueDate) {
        payload.due_date = moment(new Date(this.userPaymentForm.value.dueDate).setHours(23, 59)).format()
      }

      if (this.userPaymentForm.value.status === PaidStatus.PaidManually) {
        payload.charge_made = this.userPaymentForm.value.chargeDate
      }

      switch (this.paymentType) {
        case PaymentTypes.MembershipFee:
          payload.custom_price_option = this.isSettingCustomPrice
            ? {
                amount: this.utils.getOre(this.userPaymentForm.controls.customPrice.value),
                include_fee: this.userPaymentForm.value.includeTransactionFee
              }
            : null
          break
        case PaymentTypes.RegularPayment:
        case PaymentTypes.TrainingFee:
          if (this.utils.isNumber(this.userPaymentForm.value.amount)) {
            payload.amount = this.utils.getOre(this.userPaymentForm.value.amount)
          }
          if (this.userPaymentForm.value.includeTransactionFee !== undefined) {
            payload.include_fee = this.userPaymentForm.value.includeTransactionFee
          }
      }

      this.economyTabService
        .updateUserPayment(this.userPaymentId, payload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this.snackbarService.success(
            this.translate.instant('payment.payment_update_success', {
              name: `${this.user.first_name} ${this.user.last_name}`
            })
          )
          this.close.emit(true)
        })
    }
  }

  onClose(): void {
    this.close.emit(false)
  }
}
